import React from 'react'
import cx from 'classnames'
import { prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ science }) => {
  if (!science || science.isHide) return null

  return (
    <div className={styles.science}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{science.title}</h2>
          <a href={science.linkUrl} target="_blank" className={styles.link}>
            {science.linkText}
          </a>
        </div>

        <div className={styles.info}>
          <div className={styles.infoList}>
            {science.ingredients &&
              science.ingredients.map((ingredient, index) => (
                <div className={styles.ingredient} key={ingredient._key}>
                  <div className={styles.col1}>
                    {ingredient.title1 && <p className={styles.colTitle}>{ingredient.title1}</p>}
                    <p className={styles.key}>{ingredient.key}</p>
                  </div>
                  <div className={styles.col2}>
                    {ingredient.title2 && <p className={styles.colTitle}>{ingredient.title2}</p>}
                    <p className={styles.description}>{prepareParagraph(ingredient.howItWorks)}</p>
                  </div>
                  <div className={styles.col3}>
                    {ingredient.title3 && <p className={styles.colTitle}>{ingredient.title3}</p>}
                    <p className={styles.description}>{prepareParagraph(ingredient.description)}</p>
                  </div>
                </div>
              ))}
          </div>
          <div className={styles.infoDrugs}>
            <div className={styles.infoBox}>
              <p className={styles.infoDrugsTitle}>{science.drugFacts?.title}</p>
              {science.drugFacts?.items &&
                science.drugFacts.items.map(row => (
                  <div className={styles.supplement} key={row._key}>
                    <p className={styles.supplementTitle}>{row.title}</p>
                    <p className={styles.supplementValue}>{row.value}</p>
                  </div>
                ))}
            </div>
            <p className={styles.offLabels}>{prepareParagraph(science.offLabels)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
