import React from 'react'
import { prepareParagraph } from '../../../utils/format'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ reviews }) => {
  if (!reviews || reviews.isHide) return null

  return (
    <div className={styles.reviews}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.top}>
            <h2 className={styles.title}>{reviews.title}</h2>
            <a href={reviews.referenceLink} target="_blank" className={styles.reference}>
              {reviews.referenceText}
            </a>
          </div>

          <div className={styles.info}>
            <p className={styles.infoCount}>{reviews.reviewsCount}</p>
            <p className={styles.infoOrder}>{reviews.reviewsOrder}</p>
          </div>

          <div className={styles.list}>
            {reviews.items &&
              reviews.items.map((item, index) => (
                <div className={styles.item} key={item._key}>
                  <div className={styles.left}>
                    <div className={styles.user}>
                      <div className={styles.userInfo}>
                        <p className={styles.name}>{item.name}</p>
                        <p className={styles.verified}>Verified buyer</p>
                      </div>
                    </div>
                    <p className={styles.protocol}>
                      Reviewing: <span>{item.protocol}</span>
                    </p>
                  </div>
                  <div className={styles.middle}>
                    <p className={styles.header}>{item.title}</p>
                    <p className={styles.body}>{prepareParagraph(item.review)}</p>
                  </div>
                  <div className={styles.right}>
                    <p className={styles.date}>{item.date}</p>
                  </div>
                </div>
              ))}
          </div>

          <div className={styles.link}>
            <a
              className={styles.button}
              href={reviews.buttonUrl}
              target="_blank"
              dangerouslySetInnerHTML={{ __html: reviews.buttonText }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
